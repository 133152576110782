import React from 'react';
import BlogCard from "./BlogCard";

const BlogList = ({ blogs }) => {
    return (
        <div className={`grid gap-8 ${blogs.length === 1 ? 'grid-cols-1' : 'sm:grid-cols-2 lg:grid-cols-3'}`}>
            {blogs.map((blog) => (
                <BlogCard key={blog.slug} blog={blog}/>
            ))}
        </div>
    );
};

export default BlogList;
