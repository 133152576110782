import { motion } from "framer-motion";

const HeroSection = () => {
    return (
        <motion.section
            // Section container
            className="relative text-white text-center py-40"

            // Initial background position (starting point of the gradient animation)
            initial={{backgroundPosition: "20% 30%"}}

            // Animate the background position with smoother transitions
            animate={{backgroundPosition: ["20% 30%", "50% 50%", "20% 30%"]}}

            // Duration and easing for the background animation (20 seconds, linear, infinite loop)
            transition={{duration: 15, ease: "linear", repeat: Infinity}}

            // Adjusted background gradient (70% Blue on left, 30% Orange on right with smoother blending)
            style={{
                background: "linear-gradient(90deg, #1E3A8A 40%, #1E3A8A 60%, #F97316 90%)", // Adjusted angle to 90deg for blue on left and orange on right
                backgroundSize: "200% 200%", // Ensures the gradient has more space to animate smoothly
            }}
        >
            {/* Text Container */}
            <div className="max-w-6xl mx-auto px-2 md:px-4">
                {/* Heading Animation */}
                <motion.h1
                    className="font-heading text-5xl md:text-6xl font-extrabold leading-tight mb-6"

                    // Heading fades in with a subtle upward movement
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 1.2, ease: "easeOut"}}
                >
                    Unlock Business Growth with Automation
                </motion.h1>

                {/* Paragraph Animation */}
                <motion.p
                    className="text-xl max-w-2xl mx-auto mb-10"

                    // Paragraph text also fades in with a slight upward movement
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 1.5, ease: "easeOut"}}
                >
                    Discover how Scheduco's automation tools can streamline your operations, optimize your schedule, and
                    help your business grow effortlessly.
                </motion.p>

                {/* Button with no special hover effect */}
                <a
                    href="/signup"
                    className="bg-accentOrange text-white px-6 py-3 rounded-lg font-semibold shadow-md hover:bg-white hover:text-primaryBlue transition-all duration-300"
                >
                    Get Early Access
                </a>
            </div>
        </motion.section>
    );
};

export default HeroSection;
