import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative bg-white rounded-lg p-8 w-full max-w-lg"> {/* Set this to relative */}
                <button
                    className="absolute top-2 right-2 p-2 rounded-full text-gray-500 hover:text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primaryBlue focus:ring-opacity-50 transition duration-200"
                    onClick={onClose}
                    aria-label="Close modal"
                >
                    ✕
                </button>

                {children}
            </div>
        </div>
    );
};

export default Modal;
