import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogBySlug } from '../../api/blogApi';  // Updated to use getBlogBySlug

const ViewBlogPost = () => {
    const { slug } = useParams();  // Retrieve slug from URL
    const [blog, setBlog] = useState(null);  // State for the blog post
    const [loading, setLoading] = useState(true);  // State for loading
    const [error, setError] = useState(null);  // State for errors

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const result = await getBlogBySlug(slug);  // Fetch blog by slug
                setBlog(result);
            } catch (error) {
                setError('Error fetching blog post');
            } finally {
                setLoading(false);
            }
        };

        if (slug) {  // Ensure the fetch happens only if slug is defined
            fetchBlog();
        }
    }, [slug]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p className="text-primaryBlue font-heading text-2xl animate-pulse">Loading blog post...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p className="text-red-500 text-xl">{error}</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            {blog ? (
                <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
                    {/* Blog Title */}
                    <h1 className="font-heading text-5xl text-primaryBlue font-extrabold mb-6">{blog.title}</h1>

                    {/* Blog Content */}
                    <div className="prose max-w-none text-gray-700 mb-8">
                        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                    </div>

                    {/* Blog Metadata */}
                    <div className="text-sm text-gray-500 mb-6">
                        <p>Status: <span className={`font-bold ${blog.status === 'published' ? 'text-green-500' : 'text-yellow-500'}`}>{blog.status}</span></p>
                        <p>Published on: {new Date(blog.publishedAt).toLocaleDateString()}</p>
                    </div>

                </div>
            ) : (
                <div className="flex justify-center items-center min-h-screen">
                    <p className="text-xl text-red-500">Blog post not found.</p>
                </div>
            )}
        </div>
    );
};

export default ViewBlogPost;
