import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../../api/userApi';  // Assuming this is the API function

const EmailVerificationPage = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const verifyUserEmail = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');  // Get the token from URL

            if (!token) {
                setError('No verification token found in the URL.');
                setLoading(false);
                return;
            }

            try {
                // Pass the token to the API to verify the email and get access and refresh tokens
                const response = await verifyEmail(token);
                console.log("Email verification response:", response);

                // Store the access and refresh tokens from the backend in localStorage
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);


                // Set success message and redirect to the dashboard
                setMessage('Email verified and you are logged in successfully! Redirecting to your dashboard...');
                setTimeout(() => navigate('/ask-mfa'), 2000);  // Redirect to dashboard after 2 seconds
            } catch (err) {
                console.error("Email verification error:", err);
                setError(err.response?.data?.error || 'Failed to verify email. The link may have expired.');
            } finally {
                setLoading(false);
            }
        };

        verifyUserEmail();
    }, [location.search, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg text-center">
                {loading ? (
                    <p className="text-primaryBlue font-heading text-2xl animate-pulse">Verifying...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <p className="text-green-500">{message}</p>
                )}
            </div>
        </div>
    );
};

export default EmailVerificationPage;
