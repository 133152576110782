import React, { useEffect, useState } from 'react';
import { deleteBlogPost, getAllBlogs } from '../../api/blogApi';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
    const [blogs, setBlogs] = useState([]);
    const [password, setPassword] = useState('');  // State for the password input
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // Track if user is authenticated

    const adminPassword = 'admin123';  // Temporary hardcoded password (not secure)

    // Check for existing session in localStorage when component mounts
    useEffect(() => {
        const session = localStorage.getItem('adminAuthenticated');
        if (session) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const fetchBlogs = async () => {
                try {
                    const result = await getAllBlogs();  // Fetch all blogs, regardless of status
                    setBlogs(result.data);
                } catch (error) {
                    console.error('Error fetching blogs:', error);
                }
            };
            fetchBlogs();
        }
    }, [isAuthenticated]);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === adminPassword) {
            setIsAuthenticated(true);  // Authenticate user
            localStorage.setItem('adminAuthenticated', 'true');  // Store session in localStorage
        } else {
            alert('Incorrect password, please try again.');
        }
    };

    const handleDelete = async (slug) => {
        try {
            await deleteBlogPost(slug);  // Use slug to delete blog post
            setBlogs(blogs.filter((blog) => blog.slug !== slug));  // Filter by slug, not _id
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('adminAuthenticated');  // Remove session from localStorage
        setIsAuthenticated(false);  // Reset authentication state
    };

    if (!isAuthenticated) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-backgroundLight">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <h1 className="text-2xl font-heading font-extrabold text-primaryBlue mb-4 text-center">Admin Login</h1>
                    <form onSubmit={handlePasswordSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">Enter Password:</label>
                            <input
                                type="password"
                                className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter admin password"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full bg-accentOrange text-white py-3 rounded-lg font-semibold hover:bg-primaryBlue transition duration-300">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="font-heading text-5xl text-primaryBlue font-extrabold mb-10 text-center">
                    Admin Dashboard
                </h1>

                {/* Button to create a new post */}
                <div className="text-right mb-6">
                    <Link
                        to="/admin/create"
                        className="bg-accentOrange text-white px-6 py-3 rounded-lg font-semibold hover:bg-primaryBlue transition-all duration-300"
                    >
                        Create New Post
                    </Link>
                </div>

                {/* Logout Button */}
                <div className="text-right mb-6">
                    <button
                        onClick={handleLogout}
                        className="bg-red-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-red-600 transition-all duration-300"
                    >
                        Logout
                    </button>
                </div>

                {/* Blog List */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {blogs.length === 0 ? (
                        <p className="text-center text-xl text-gray-600">No posts available.</p>
                    ) : (
                        blogs.map((blog) => (
                            <div key={blog.slug} className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                <h2 className="font-heading text-2xl text-primaryBlue mb-2">
                                    {blog.title}
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    Status: <span className={`font-bold ${blog.status === 'published' ? 'text-green-500' : 'text-yellow-500'}`}>
                                        {blog.status}
                                    </span>
                                </p>

                                {/* Display publish/scheduled date based on status */}
                                {blog.status === 'published' ? (
                                    <p className="text-gray-600 mb-4">Published on: {new Date(blog.publishedAt).toLocaleDateString()}</p>
                                ) : blog.status === 'scheduled' ? (
                                    <p className="text-gray-600 mb-4">Scheduled for: {new Date(blog.scheduledAt).toLocaleDateString()}</p>
                                ) : null}

                                {/* Action Links */}
                                <div className="flex space-x-4">
                                    <Link to={`/admin/view/${blog.slug}`} className="text-green-500 font-semibold hover:underline">
                                        View
                                    </Link>
                                    <Link to={`/admin/edit/${blog.slug}`} className="text-blue-500 font-semibold hover:underline">
                                        Edit
                                    </Link>
                                    <button
                                        onClick={() => handleDelete(blog.slug)}  // Use slug for deletion
                                        className="text-red-500 font-semibold hover:underline"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
