import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const BlogNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);  // State for mobile menu

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-white shadow-md fixed top-0 w-full z-10">
            <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                {/* Logo */}
                <Link to="/" className="flex items-center">
                    <img
                        src="/images/Logo%20files/PNGs%20-%20SVGs/4x/Asset_2@4x-8.png" // Update this path to your logo image
                        alt="Logo"
                        className="h-10 w-auto" // Adjust height and width as needed
                    />
                </Link>

                {/* Hamburger Icon for Mobile */}
                <div className="lg:hidden">
                    <button onClick={toggleMenu} className="text-primaryBlue focus:outline-none">
                        {isOpen ? (
                            <XMarkIcon className="h-6 w-6" />
                        ) : (
                            <Bars3Icon className="h-6 w-6" />
                        )}
                    </button>
                </div>

                {/* Links for Desktop */}
                <ul className="hidden lg:flex space-x-8 font-body text-lg">
                    <li>
                        <Link to="/" className="text-gray-700 hover:text-primaryBlue transition">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog" className="text-gray-700 hover:text-primaryBlue transition">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" className="text-gray-700 hover:text-primaryBlue transition">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className="text-gray-700 hover:text-primaryBlue transition">
                            Contact Us
                        </Link>
                    </li>
                </ul>

                {/* Call-to-action Button for Desktop */}
                <Link
                    to="/signup"
                    className="hidden lg:inline-block bg-primaryBlue text-white px-6 py-2 rounded-lg font-heading font-semibold hover:bg-accentOrange transition"
                >
                    Get Early Access
                </Link>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <ul className="md:hidden bg-white px-4 pt-2 pb-4 space-y-2 font-body text-lg shadow-md">
                    <li>
                        <Link to="/" onClick={toggleMenu} className="block text-gray-700 hover:text-primaryBlue transition">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog" onClick={toggleMenu} className="block text-gray-700 hover:text-primaryBlue transition">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={toggleMenu} className="block text-gray-700 hover:text-primaryBlue transition">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={toggleMenu} className="block text-gray-700 hover:text-primaryBlue transition">
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/signup"
                            onClick={toggleMenu}
                            className="block text-center bg-primaryBlue text-white px-4 py-2 rounded-lg font-heading font-semibold hover:bg-accentOrange transition"
                        >
                            Get Early Access
                        </Link>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default BlogNavbar;
