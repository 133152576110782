import React, { useState } from 'react';
import { forgotPassword } from '../../api/userApi';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await forgotPassword(email);
            setSuccessMessage(response.message);
        } catch (err) {
            setError(err.error || 'Failed to send password reset link.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6 text-center">Forgot Password</h1>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}
                <form onSubmit={handleSubmit} className="grid gap-4">
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-primaryBlue text-white py-2 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Sending...' : 'Send Password Reset Link'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
