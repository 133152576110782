import axios from 'axios';

// Define the API base URL from environment variables or a default value
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to send contact form data to the backend
export const sendContactForm = async (formData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/contact`, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;  // If the request is successful
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Failed to send your message. Please try again.');
    }
};
