import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { allCountries } from 'country-telephone-data'; // Ensure this package is installed
import { registerUser } from '../../api/userApi'; // Adjust the import path if necessary

// Prepare country options
const countryOptions = allCountries.map((country) => ({
    value: country.iso2.toUpperCase(), // Use ISO code as value
    label: country.name.replace(/\s*\(.*?\)\s*/g, ''), // Clean up labels
    dialCode: `+${country.dialCode}`,
}));

const ConfirmCountryPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Extract state from the location (if available)
    const { ipCountry, phoneCountry, selectedCountry, formData } = location.state || {};

    // Component state
    const [businessCountry, setBusinessCountry] = useState(selectedCountry || '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Handle country selection change
    const handleCountryChange = (e) => {
        setBusinessCountry(e.target.value);
    };

    // Handle confirm button click
    const handleConfirm = async () => {
        if (!businessCountry) {
            setError('Please select a business country.');
            return;
        }

        setLoading(true);
        setError(null);

        // Prepare the updated form data
        const updatedFormData = { ...formData, country: businessCountry };

        try {
            // API call to register user
            await registerUser(updatedFormData);

            // Navigate back to registration page with success message
            navigate('/register', {
                state: {
                    successMessage: 'Registration successful! Please check your email to verify your account.',
                },
            });
        } catch (err) {
            // Handle any errors from the API
            const errorMessage = err.response?.data?.error || err.message || 'Failed to register.';
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6 text-center">
                    Confirm Your Business Country
                </h1>
                {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
                <p className="mb-4 text-center">
                    We detected a mismatch in your country information. Please confirm the country where your business operates.
                </p>
                <div className="mb-4">
                    <p>
                        <strong>IP Country:</strong> {ipCountry || 'Unknown'}
                    </p>
                    <p>
                        <strong>Phone Country:</strong> {phoneCountry || 'Unknown'}
                    </p>
                    <p>
                        <strong>Selected Country:</strong> {selectedCountry || 'Unknown'}
                    </p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">
                        Business Country
                    </label>
                    <select
                        value={businessCountry}
                        onChange={handleCountryChange}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                    >
                        <option value="" disabled>
                            Select your business country
                        </option>
                        {countryOptions.map((country) => (
                            <option key={country.value} value={country.value}>
                                {country.label} ({country.dialCode})
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    onClick={handleConfirm}
                    className={`w-full p-3 rounded-lg text-white transition duration-300 ${loading ? 'bg-gray-400' : 'bg-primaryBlue hover:bg-primaryBlue-dark'}`}
                    disabled={loading}
                >
                    {loading ? 'Saving...' : 'Confirm and Register'}
                </button>
            </div>
        </div>
    );
};

export default ConfirmCountryPage;
