import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlogBySlug, updateBlogPost } from '../../api/blogApi';
import { Editor } from '@tinymce/tinymce-react';

const EditBlogPost = () => {
    const { slug } = useParams();  // Get the slug from the URL
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        metaTitle: '',
        metaDescription: '',
        keywords: '',
        status: 'draft',
        publishDate: '',
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const blog = await getBlogBySlug(slug);  // Fetch by slug
                setFormData({
                    title: blog.title,
                    content: blog.content,
                    metaTitle: blog.metaTitle,
                    metaDescription: blog.metaDescription,
                    keywords: blog.keywords.join(','),  // Join keywords array back into a string
                    status: blog.status,
                    publishDate: blog.publishDate ? blog.publishDate.split('T')[0] : '',  // Format publishDate for the date input field
                });
            } catch (error) {
                setError('Error fetching blog post');
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [slug]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle TinyMCE content change
    const handleEditorChange = (content) => {
        setFormData((prevData) => ({
            ...prevData,
            content,  // Update content with TinyMCE data
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await updateBlogPost(slug, {
                ...formData,
                keywords: formData.keywords.split(',').map(k => k.trim()),
            });
            navigate('/admin');
        } catch (error) {
            setError('Error updating blog post');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <p className="text-primaryBlue font-heading text-2xl animate-pulse">Loading...</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-4xl mx-auto bg-white p-10 rounded-lg shadow-lg">
                <h1 className="font-heading text-4xl text-primaryBlue font-extrabold mb-8 text-center">Edit Blog Post</h1>

                {error && <p className="text-red-500 text-center mb-6">{error}</p>}

                <form onSubmit={handleSubmit} className="grid gap-6">
                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="title">Title</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Enter the blog title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    {/* Content Field (TinyMCE Editor) */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Content</label>
                        <Editor
                            apiKey='jacno6c6i42xq1sgc2mu36zuvnre27qijvv4bj3smqa7zfxx'
                            init={{
                                plugins: [
                                    'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                    'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
                                ],
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                tinycomments_mode: 'embedded',
                                tinycomments_author: 'Author name',
                            }}
                            value={formData.content}
                            onEditorChange={handleEditorChange}
                        />
                    </div>

                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="metaTitle">SEO Meta Title</label>
                        <input
                            type="text"
                            id="metaTitle"
                            name="metaTitle"
                            placeholder="SEO Meta Title"
                            value={formData.metaTitle}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="metaDescription">SEO Meta Description</label>
                        <textarea
                            id="metaDescription"
                            name="metaDescription"
                            placeholder="SEO Meta Description"
                            value={formData.metaDescription}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            rows="4"
                            required
                        />
                    </div>

                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="keywords">Keywords (comma-separated)</label>
                        <input
                            type="text"
                            id="keywords"
                            name="keywords"
                            placeholder="Enter keywords (comma separated)"
                            value={formData.keywords}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="status">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        >
                            <option value="draft">Draft</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="published">Published</option>
                        </select>
                    </div>

                    {/* Publish Date Field */}
                    <div>
                        <label className="block font-semibold text-gray-700 mb-2" htmlFor="publishDate">Publish Date</label>
                        <input
                            type="date"
                            id="publishDate"
                            name="publishDate"
                            value={formData.publishDate}
                            onChange={handleChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-accentOrange text-white py-3 px-6 rounded-lg font-semibold hover:bg-primaryBlue transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Post'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditBlogPost;
