// src/pages/signup/index.jsx

// Import statements should be at the top level, outside of any functions or blocks
import React, { useState } from 'react';
import { subscribeToWaitlist } from '../../api/signupApi';  // Ensure this is placed at the top

const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await subscribeToWaitlist(email);  // Use the API function
            setSubmitted(true);
        } catch (err) {
            setError('Failed to subscribe. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-2xl mx-auto bg-white p-10 rounded-lg shadow-lg">
                <h1 className="font-heading text-5xl text-primaryBlue font-extrabold mb-6 text-center">Join the Waitlist</h1>
                <p className="text-lg text-gray-600 text-center mb-8">
                    Be the first to know when <span className="font-semibold text-primaryBlue">Scheduco</span> launches.
                </p>

                {submitted ? (
                    <div className="text-center">
                        <p className="text-green-600 font-semibold">
                            Thanks for signing up! We'll notify you when the platform is ready.
                        </p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="grid gap-6">
                        <div>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                required
                            />
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </div>
                        <button
                            type="submit"
                            className="bg-accentOrange text-white py-3 px-6 rounded-lg font-semibold hover:bg-primaryBlue transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Signing up...' : 'Join the Waitlist'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SignupPage;
