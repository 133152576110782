import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Fetch all published blogs (public view)
export const getAllPublishedBlogs = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/blogs`, {
            params: { page, limit, sort: 'publishedAt' },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching published blogs:', error.response ? error.response.data : error.message);
        throw new Error('Error fetching published blogs');
    }
};

// Fetch a blog post by slug (public and admin view)
export const getBlogBySlug = async (slug) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/blogs/${slug}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching blog by slug (${slug}):`, error.response ? error.response.data : error.message);
        throw new Error('Error fetching blog by slug');
    }
};

// Fetch all blogs for admin (any status)
export const getAllBlogs = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/blogs/all`, {
            params: { page, limit, sort: 'createdAt' },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching all blogs:', error.response ? error.response.data : error.message);
        throw new Error('Error fetching all blogs');
    }
};

// Create a new blog post
export const createBlogPost = async (blogData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/blogs`, blogData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating blog post:', error.response ? error.response.data : error.message);
        throw new Error('Error creating blog post');
    }
};

// Update a blog post (slug regenerates if title changes)
export const updateBlogPost = async (slug, blogData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/api/blogs/${slug}`, blogData);
        return response.data;
    } catch (error) {
        console.error(`Error updating blog post with slug (${slug}):`, error.response ? error.response.data : error.message);
        throw new Error('Error updating blog post');
    }
};

// Delete a blog post by slug
export const deleteBlogPost = async (slug) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/api/blogs/${slug}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting blog post with slug (${slug}):`, error.response ? error.response.data : error.message);
        throw new Error('Error deleting blog post');
    }
};
