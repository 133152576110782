import api from "./api";

const API_BASE_URL = process.env.REACT_APP_API_URL; // Assuming this is set up in your .env file

// Get all customers with optional filters, pagination, and sorting
export const getCustomers = async (params, accessToken) => {
    const response = await api.get(`${API_BASE_URL}/api/customers`, {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        }
    });
    return response.data;
};

// Get a customer by ID
export const getCustomerById = async (id, accessToken) => {
    const response = await api.get(`${API_BASE_URL}/api/customers/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        }
    });
    return response.data;
};

// Create a new customer
export const createCustomer = async (customerData, accessToken) => {
    const response = await api.post(`${API_BASE_URL}/api/customers`, customerData, {
        headers: {
            Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        }
    });
    return response.data;
};

// Update an existing customer by ID
export const updateCustomer = async (id, customerData, accessToken) => {
    const response = await api.put(`${API_BASE_URL}/api/customers/${id}`, customerData, {
        headers: {
            Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        }
    });
    return response.data;
};

// Delete a customer by ID
export const deleteCustomer = async (id, accessToken) => {
    const response = await api.delete(`${API_BASE_URL}/api/customers/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        }
    });
    return response.data;
};
