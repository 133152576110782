import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import {logoutUser} from "../../api/userApi";

const DashboardNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);  // State for mobile menu
    const navigate = useNavigate(); // Use React Router's navigate to redirect

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle logout
    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken'); // Get the refresh token from localStorage
            if (!refreshToken) {
                console.error('No refresh token available for logout');
                return;
            }

            // Call the logoutUser function to log the user out
            await logoutUser(refreshToken);


            // Redirect to the login page
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <nav className="bg-gray-900 text-white shadow-md fixed top-0 w-full z-10">
            <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                {/* Logo */}
                <Link to="/dashboard" className="text-3xl font-heading font-extrabold">
                    Dashboard
                </Link>

                {/* Hamburger Icon for Mobile */}
                <div className="sm:hidden">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        {isOpen ? (
                            <XMarkIcon className="h-6 w-6" />
                        ) : (
                            <Bars3Icon className="h-6 w-6" />
                        )}
                    </button>
                </div>

                {/* Links for Desktop */}
                <ul className="hidden sm:flex space-x-8 font-body text-lg">
                    <li>
                        <Link to="/dashboard" className="hover:text-gray-300 transition">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/customers" className="hover:text-gray-300 transition">
                            Customers
                        </Link>
                    </li>
                    <li>
                        <Link to="/jobs" className="hover:text-gray-300 transition">
                            Jobs
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings" className="hover:text-gray-300 transition">
                            Settings
                        </Link>
                    </li>
                </ul>

                {/* Call-to-action Button for Desktop */}
                <button
                    onClick={handleLogout}
                    className="hidden sm:inline-block bg-red-500 text-white px-6 py-2 rounded-lg font-semibold hover:bg-red-600 transition"
                >
                    Logout
                </button>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <ul className="sm:hidden bg-gray-900 px-4 pt-2 pb-4 space-y-2 shadow-md">
                    <li>
                        <Link to="/dashboard" onClick={toggleMenu} className="block hover:text-gray-300 transition">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/customers" onClick={toggleMenu} className="block hover:text-gray-300 transition">
                            Customers
                        </Link>
                    </li>
                    <li>
                        <Link to="/jobs" onClick={toggleMenu} className="block hover:text-gray-300 transition">
                            Jobs
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings" onClick={toggleMenu} className="block hover:text-gray-300 transition">
                            Settings
                        </Link>
                    </li>
                    <li>
                        <button
                            onClick={handleLogout}
                            className="block text-center bg-red-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-red-600 transition"
                        >
                            Logout
                        </button>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default DashboardNavbar;
