import React from 'react';
import { useLocation } from 'react-router-dom';
import BlogNavbar from './BlogNavbar';
import DashboardNavbar from './DashboardNavbar';

const ConditionalNavbar = () => {
    const location = useLocation();
    const publicRoutes = ['/', '/about', '/contact', '/signup', '/login', '/register'];

    // Determine if the current route is a public route
    const isPublicRoute = publicRoutes.includes(location.pathname) || location.pathname.startsWith('/blog');

    return isPublicRoute ? <BlogNavbar /> : <DashboardNavbar />;
};

export default ConditionalNavbar;
