import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { enableMfa } from "../../api/userApi";

const AskMfaPage = () => {
    const [qrCode, setQrCode] = useState(null);
    // eslint-disable-next-line
    const [mfaEnabled, setMfaEnabled] = useState(false);
    const navigate = useNavigate();

    const handleEnableMfa = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');  // Ensure you're using the access token
            const mfaData = await enableMfa(accessToken);
            setQrCode(mfaData.qrCode);
            setMfaEnabled(true);
        } catch (error) {
            console.error('Error enabling MFA:', error);
        }
    };

    const handleSkip = () => {
        navigate('/dashboard');
    };

    const handleContinueToConfirm = () => {
        navigate('/confirm-mfa');  // Redirect to the MFA confirmation page
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg text-center">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6">Enable MFA for Extra Security</h1>

                {qrCode ? (
                    <>
                        <p className="text-gray-600 mb-6">Scan the QR code below with your authenticator app.</p>
                        <img src={qrCode} alt="MFA QR Code" className="mx-auto mb-6" />
                        <button
                            onClick={handleContinueToConfirm}
                            className="bg-primaryBlue text-white py-2 px-6 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                        >
                            Continue to Confirm
                        </button>
                    </>
                ) : (
                    <>
                        <p className="text-gray-600 mb-6">
                            Would you like to enable Multi-Factor Authentication (MFA) for enhanced security?
                        </p>
                        <div className="grid gap-4">
                            <button
                                onClick={handleEnableMfa}
                                className="bg-primaryBlue text-white py-2 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                            >
                                Yes, Enable MFA
                            </button>
                            <button
                                onClick={handleSkip}
                                className="bg-gray-400 text-white py-2 rounded-lg font-semibold hover:bg-gray-600 transition duration-300"
                            >
                                No, Skip for Now
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AskMfaPage;
