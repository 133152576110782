import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const AddressForm = ({ formData, setFormData, userCountry }) => {
    const geocoderContainer = useRef(null);
    const geocoderRef = useRef(null);

    useEffect(() => {
        if (!mapboxgl.accessToken) {
            console.error('Mapbox access token is missing.');
            return;
        }

        const initializeGeocoder = () => {
            if (geocoderRef.current) {
                geocoderRef.current.onRemove();
            }

            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                types: 'postcode,address',
                countries: userCountry?.toLowerCase() || '',
                placeholder: 'Search by postcode or address',
                limit: 100,
                mapboxgl,
            });

            geocoder.addTo(geocoderContainer.current);
            geocoderRef.current = geocoder;

            geocoder.on('result', (e) => {
                const addressComponents = e.result.place_name.split(',');
                const street = addressComponents[0];
                const city = addressComponents[1] ? addressComponents[1].trim() : '';
                const state = addressComponents[2] ? addressComponents[2].trim() : '';
                const country = addressComponents[addressComponents.length - 1];
                const postalCode = e.result.context.find((c) => c.id.includes('postcode'))?.text || '';

                setFormData((prev) => ({
                    ...prev,
                    address: {
                        street1: street,
                        city,
                        state,
                        country,
                        postalCode,
                    },
                }));
            });
        };

        initializeGeocoder();

        return () => {
            if (geocoderRef.current) {
                geocoderRef.current.onRemove();
                geocoderRef.current = null;
            }
        };
    }, [userCountry, setFormData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value,
            },
        }));
    };

    return (
        <>
            {/* Geocoder Container */}
            <div
                ref={geocoderContainer}
                className="flex-grow justify-center mapboxgl-ctrl-geocoder"
            ></div>

            {/* Address Form */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <input
                    type="text"
                    name="street1"
                    value={formData.address.street1 || ''}
                    onChange={handleChange}
                    placeholder="Street Address"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                />
                <input
                    type="text"
                    name="city"
                    value={formData.address.city || ''}
                    onChange={handleChange}
                    placeholder="City"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                />
                <input
                    type="text"
                    name="state"
                    value={formData.address.state || ''}
                    onChange={handleChange}
                    placeholder="State"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                />
                <input
                    type="text"
                    name="country"
                    value={formData.address.country || userCountry || ''}
                    onChange={handleChange}
                    placeholder={userCountry || "Country"}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                />
                <input
                    type="text"
                    name="postalCode"
                    value={formData.address.postalCode || ''}
                    onChange={handleChange}
                    placeholder="Postal Code"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                />
            </div>
        </>
    );
};

export default AddressForm;
