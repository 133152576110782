// src/api/signupApi.js
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const subscribeToWaitlist = async (email) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/subscribe`, {
            email: email,  // Sending email in the request body
        });

        return response.data;  // Return the data from the response
    } catch (error) {
        throw new Error('Failed to subscribe.');
    }
};
