import React from 'react';
import WorldFlag from 'react-world-flags'; // Import the world flags component

const CountryConfirmationModal = ({ countryMismatchData, formData, handleCountryChange, handleConfirmCountry, loading }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6 text-center">Confirm Your Business Country</h1>
                <p className="mb-4 text-center">
                    We detected a mismatch in your country information. Please confirm the country where your business operates.
                </p>

                {/* Country Buttons with space around */}
                <div className="flex justify-around items-center mb-4">
                    {/* Phone Country */}
                    <button
                        className={`flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md 
                        ${formData.country === countryMismatchData?.phoneCountry ? 'ring-2 ring-primaryBlue' : ''}`}
                        onClick={() => handleCountryChange({ target: { value: countryMismatchData?.phoneCountry } })}
                    >
                        <WorldFlag code={countryMismatchData?.phoneCountry || 'Unknown'} className="h-12 w-12 mb-2" />
                        <p className="text-sm">{countryMismatchData?.phoneCountry || 'Phone Country'}</p>
                    </button>

                    {/* IP Country */}
                    <button
                        className={`flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md 
                        ${formData.country === countryMismatchData?.ipCountry ? 'ring-2 ring-primaryBlue' : ''}`}
                        onClick={() => handleCountryChange({ target: { value: countryMismatchData?.ipCountry } })}
                    >
                        <WorldFlag code={countryMismatchData?.ipCountry || 'Unknown'} className="h-12 w-12 mb-2" />
                        <p className="text-sm">{countryMismatchData?.ipCountry || 'IP Country'}</p>
                    </button>
                </div>

                {/* Confirm Button */}
                <button
                    onClick={handleConfirmCountry}
                    className={`w-full p-3 rounded-lg text-white transition duration-300 ${loading ? 'bg-gray-400' : 'bg-primaryBlue hover:bg-primaryBlue-dark'}`}
                    disabled={loading}
                >
                    {loading ? 'Saving...' : 'Confirm and Register'}
                </button>
            </div>
        </div>
    );
};

export default CountryConfirmationModal;
