import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../api/userApi';

const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const token = searchParams.get('token');  // Get the token from URL

        try {
            const response = await resetPassword(token, newPassword);
            setSuccessMessage(response.message);
            setTimeout(() => navigate('/login'), 3000);  // Redirect after success
        } catch (err) {
            setError(err.error || 'Failed to reset password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6 text-center">Reset Password</h1>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}
                <form onSubmit={handleSubmit} className="grid gap-4">
                    <input
                        type="password"
                        name="newPassword"
                        placeholder="Enter your new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-primaryBlue text-white py-2 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
