import React, { useState } from 'react';
import { loginUser } from '../../api/userApi';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lockoutTime, setLockoutTime] = useState(null);
    const [remainingAttempts, setRemainingAttempts] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await loginUser(formData);
            localStorage.setItem('accessToken', response.accessToken);  // Store access token in localStorage
            localStorage.setItem('refreshToken', response.refreshToken);  // Store refresh token in localStorage

            // Decode the access token to check if MFA is enabled
            const decodedToken = jwtDecode(response.accessToken);

            // Check if MFA is enabled
            if (!decodedToken.mfaEnabled) {
                // Redirect to Ask MFA page if MFA is not enabled
                navigate('/ask-mfa');
            } else {
                // Otherwise, redirect to dashboard
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.error && err.error.includes('Account is locked')) {
                const match = err.error.match(/after (\d+) minutes/);
                const time = match ? parseInt(match[1], 10) : 120;
                setLockoutTime(time);
            } else if (err.error && err.error.includes('attempts remaining')) {
                const match = err.error.match(/(\d+) attempts remaining/);
                const attempts = match ? parseInt(match[1], 10) : null;
                setRemainingAttempts(attempts);
            } else {
                setError(err.error || 'Failed to login');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6 text-center">Login</h1>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {lockoutTime && (
                    <p className="text-red-500 text-center mb-4">
                        Your account is locked. Try again in {lockoutTime} minutes.
                    </p>
                )}
                {remainingAttempts !== null && (
                    <p className="text-yellow-500 text-center mb-4">
                        You have {remainingAttempts} login attempts remaining before lockout.
                    </p>
                )}
                <form onSubmit={handleSubmit} className="grid gap-4">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-primaryBlue text-white py-2 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>

                <div className="mt-6 text-center">
                    <p className="text-gray-600">
                        <Link to="/forgot-password" className="text-primaryBlue font-semibold hover:underline">
                            Forgot your password?
                        </Link>
                    </p>
                </div>

                <div className="mt-6 text-center">
                    <p className="text-gray-600">Don't have an account?</p>
                    <Link to="/signup" className="text-primaryBlue font-semibold hover:underline">
                        Sign up here
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
