import axios from 'axios';
import api from './api'; // Import the axios instance with interceptors

const API_BASE_URL = process.env.REACT_APP_API_URL;

// User Registration (Unprotected route - Called after country validation)
export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/register`, userData); // Using regular axios
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Registration failed' };
    }
};

// Country Validation (Unprotected route - Called before registration)
export const validateCountry = async (userData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/validate-country`, userData); // Validate country before registration
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Country validation failed' };
    }
};

// Email Verification (Unprotected route)
export const verifyEmail = async (token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/users/verify-email`, { // Using regular axios
            params: { token },
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Email verification failed' };
    }
};

// User Login (Unprotected route)
export const loginUser = async (userData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/login`, userData); // Using regular axios
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Login failed' };
    }
};

// Forgot Password (Unprotected route)
export const forgotPassword = async (email) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/forgot-password`, { email }); // Using regular axios
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Failed to send password reset link' };
    }
};

// Reset Password (Unprotected route)
export const resetPassword = async (token, newPassword) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/reset-password`, { token, newPassword }); // Using regular axios
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Failed to reset password' };
    }
};

// Refresh Token (Unprotected route)
export const refreshToken = async (refreshToken) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/users/refresh-token`, { refreshToken }); // Using regular axios
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Token refresh failed' };
    }
};

// Logout (Protected route)
// Make sure both tokens are passed correctly in the request
export const logoutUser = async (refreshToken) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        console.log(`Access token: ${accessToken}`);  // Log the access token for debugging

        // Make the API request to logout
        const response = await api.post(
            `${API_BASE_URL}/api/users/logout`,
            { refreshToken },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
                }
            }
        );

        // Clear tokens from localStorage after successful logout
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        return response.data;
    } catch (error) {
        console.error('Error logging out:', error);
        throw error.response ? error.response.data : { error: 'Logout failed' };
    }
};





// Enable MFA (Protected route)
export const enableMfa = async () => {
    try {
        const response = await api.post(`${API_BASE_URL}/api/users/enable-mfa`); // Using protected axios instance (api)
        return response.data; // This includes the QR code and MFA secret
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Failed to enable MFA' };
    }
};

// Confirm MFA (Protected route)
export const confirmMfaToken = async (totpCode) => {
    try {
        const response = await api.post(`${API_BASE_URL}/api/users/confirm-mfa`, { token: totpCode }); // Using protected axios instance (api)
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'MFA confirmation failed' };
    }
};

// Send MFA SMS Code (Protected route)
export const sendMfaSms = async () => {
    try {
        const response = await api.post(`${API_BASE_URL}/api/users/send-mfa-sms`); // Using protected axios instance (api)
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Failed to send MFA SMS' };
    }
};
