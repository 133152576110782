import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getBlogBySlug, getAllPublishedBlogs } from '../api/blogApi';

const BlogPostPage = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [allBlogs, setAllBlogs] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const result = await getBlogBySlug(slug);
                setBlog(result);
            } catch (error) {
                console.error('Error fetching blog post:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchAllBlogs = async () => {
            try {
                const blogList = await getAllPublishedBlogs();
                setAllBlogs(blogList.data);
                const index = blogList.data.findIndex((b) => b.slug === slug);
                setCurrentIndex(index);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlog();
        fetchAllBlogs();
    }, [slug]);

    const previousBlog = currentIndex > 0 ? allBlogs[currentIndex - 1] : null;
    const nextBlog = currentIndex < allBlogs.length - 1 ? allBlogs[currentIndex + 1] : null;

    return (
        <div className="p-4 md:p-8 min-h-screen bg-backgroundLightGray">
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <p className="text-primaryBlue font-heading text-2xl animate-pulse">
                        Loading blog post...
                    </p>
                </div>
            ) : blog ? (
                <>
                    <div className="max-w-4xl mx-auto bg-white p-6 md:p-8 rounded-lg shadow-lg mb-4">
                        <h1 className="font-heading text-3xl md:text-5xl font-extrabold text-primaryBlue">
                            {blog.title}
                        </h1>
                    </div>
                    <div className="max-w-4xl mx-auto bg-white p-6 md:p-8 rounded-lg shadow-lg">
                        <div className="prose prose-sm md:prose lg:prose-lg text-gray-700 mb-12">
                            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                        </div>
                    </div>

                    {/* Navigation for previous and next blogs */}
                    <div className="mt-8 flex flex-col sm:flex-row justify-between">
                        {previousBlog && (
                            <Link
                                to={`/blog/${previousBlog.slug}`}
                                className="text-primaryBlue font-semibold hover:underline mb-4 sm:mb-0"
                            >
                                ← Previous Post: {previousBlog.title}
                            </Link>
                        )}
                        {nextBlog && (
                            <Link
                                to={`/blog/${nextBlog.slug}`}
                                className="text-primaryBlue font-semibold hover:underline"
                            >
                                Next Post: {nextBlog.title} →
                            </Link>
                        )}
                    </div>

                    {/* Call-to-Action section */}
                    <div className="mt-16 max-w-4xl mx-auto text-center">
                        <h2 className="font-heading text-2xl md:text-3xl font-bold text-primaryBlue mb-4">
                            Ready to Scale Your Business?
                        </h2>
                        <p className="text-lg text-gray-600 mb-6">
                            Discover how <span className="font-semibold text-primaryBlue">Scheduco</span> can
                            automate your business processes and help you grow faster.
                        </p>
                        <Link
                            to="/signup"
                            className="inline-block bg-accentOrange text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-semibold shadow-md hover:bg-primaryBlue transition-all duration-300"
                        >
                            Start Your Free Trial Today
                        </Link>
                    </div>
                </>
            ) : (
                <p className="text-center text-xl text-red-500">Blog post not found.</p>
            )}
        </div>
    );
};

export default BlogPostPage;
