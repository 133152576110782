import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDaysIcon, MapIcon, Cog6ToothIcon, UserGroupIcon } from '@heroicons/react/24/outline';


const AboutPage = () => {
    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-7xl mx-auto">
                {/* Header Section */}
                <section className="text-center mb-16">
                    <h1 className="font-heading text-5xl font-extrabold text-primaryBlue mb-4">
                        About Scheduco
                    </h1>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Scheduco is the all-in-one scheduling and business management platform designed for service-based
                        businesses with recurring customers. We streamline operations, optimize routes, and adjust schedules based on
                        real-time factors, enabling you to focus on growth.
                    </p>
                </section>

                {/* Mission Section with Icon */}
                <section className="flex flex-col md:flex-row items-center justify-between mb-16">
                    <div className="md:w-1/2">
                        <h2 className="font-heading text-4xl text-primaryBlue mb-6">Our Mission</h2>
                        <p className="text-lg text-gray-700">
                            At Scheduco, we believe in empowering service-based businesses by providing tools that eliminate inefficiencies.
                            Our goal is to simplify your workflow by automating scheduling, route optimization, and equipment management —
                            so you can spend less time on logistics and more time growing your business.
                        </p>
                    </div>
                    <div className="md:w-1/2 flex justify-center">
                        <UserGroupIcon className="h-32 w-32 text-primaryBlue" />
                    </div>
                </section>

                {/* Features Section */}
                <section className="mb-16">
                    <h2 className="font-heading text-4xl text-primaryBlue text-center mb-8">Why Scheduco?</h2>
                    <div className="space-y-16">
                        {/* Feature 1 - Smart Scheduling */}
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2">
                                <h3 className="font-heading text-2xl text-primaryBlue mb-4">Smart Scheduling</h3>
                                <p className="text-gray-600">
                                    Automate your schedule based on recurring customer needs, weather changes, and operational adjustments,
                                    making sure you're always on track.
                                </p>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <CalendarDaysIcon className="h-32 w-32 text-primaryBlue" />
                            </div>
                        </div>
                        {/* Feature 2 - Real-time Route Optimization */}
                        <div className="flex flex-col md:flex-row-reverse items-center">
                            <div className="md:w-1/2">
                                <h3 className="font-heading text-2xl text-primaryBlue mb-4">Real-time Route Optimization</h3>
                                <p className="text-gray-600">
                                    Optimize routes for maximum efficiency, saving time and fuel costs while ensuring timely service delivery.
                                </p>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <MapIcon className="h-32 w-32 text-primaryBlue" />
                            </div>
                        </div>
                        {/* Feature 3 - Equipment Management */}
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2">
                                <h3 className="font-heading text-2xl text-primaryBlue mb-4">Equipment Management</h3>
                                <p className="text-gray-600">
                                    Track, maintain, and manage your equipment seamlessly, ensuring everything you need is always ready for use.
                                </p>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <Cog6ToothIcon className="h-32 w-32 text-primaryBlue" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Team Section */}
                <section className="mb-16">
                    <h2 className="font-heading text-4xl text-primaryBlue text-center mb-8">Meet the Team</h2>
                    <div className="grid grid-cols-1 text-center">
                        {/* Solo Team Member */}
                        <div>
                            <img
                                src="/images/team1.jpeg"  // Image path relative to the public folder
                                alt="Your Name"
                                className="rounded-full h-64 w-64 mx-auto mb-4 border-4 border-primaryBlue"  // Add border classes
                            />
                            <h3 className="font-heading text-2xl text-primaryBlue mb-2">Nicolas Clabaut</h3>
                            <p className="text-gray-600">Founder, Developer & Everything In Between</p>
                        </div>
                    </div>
                </section>



                {/* Call to Action Section */}
                <section className="text-center mt-16">
                    <h2 className="font-heading text-3xl text-primaryBlue mb-4">
                        Ready to Take Your Business to the Next Level?
                    </h2>
                    <p className="text-lg text-gray-600 mb-6">
                        Get started with Scheduco today and experience the ease of automation and seamless scheduling for your business.
                    </p>
                    <Link
                        to="/signup"
                        className="bg-accentOrange text-white px-6 py-3 rounded-lg font-semibold hover:bg-primaryBlue transition-all duration-300"
                    >
                        Start Your Free Trial
                    </Link>
                </section>
            </div>
        </div>
    );
};

export default AboutPage;
