import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Plausible from 'plausible-tracker';

const PlausibleTracker = () => {
    const location = useLocation(); // Get current route location

    // Track page views and route changes
    useEffect(() => {
        // Initialize Plausible tracker inside the useEffect
        const plausible = Plausible({
            domain: 'schedu.co', // Replace with your domain
        });

        // Trigger page view on initial load
        plausible.trackPageview();
    }, []); // No dependencies needed for initial load

    useEffect(() => {
        const plausible = Plausible({
            domain: 'schedu.co', // Replace with your domain
        });

        plausible.trackPageview({
            url: location.pathname, // Track based on the current route
        });
    }, [location]); // Trigger on route changes

    return null; // No need to render anything
};

export default PlausibleTracker;
