import React, { useState, useEffect } from 'react';
import CustomerListPage from '../../pages/customer/CustomerListPage';
import { jwtDecode } from 'jwt-decode';

const ParentComponent = () => {
    const [userCountry, setUserCountry] = useState('');

    // Fetch user's country from the access token stored in localStorage
    useEffect(() => {
        const fetchUserCountryFromToken = () => {
            const accessToken = localStorage.getItem('accessToken');  // Get the token from localStorage

            if (accessToken) {
                try {
                    const decodedToken = jwtDecode(accessToken);
                    const userCountryFromToken = decodedToken?.country;  // Adjust this field based on your token structure

                    // Only update the state if the decoded country is different from the current state
                    if (userCountryFromToken && userCountryFromToken !== userCountry) {
                        setUserCountry(userCountryFromToken);
                    }
                } catch (error) {
                    console.error('Error decoding access token:', error);
                }
            }
        };

        fetchUserCountryFromToken();
    }, [userCountry]); // Add `userCountry` as a dependency to avoid repeated updates

    return (
        <div>
            {/* Only render CustomerListPage when userCountry is available */}
            {userCountry && <CustomerListPage userCountry={userCountry} />}
        </div>
    );
};

export default ParentComponent;
