import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { BoltIcon, ChartBarIcon, ClockIcon } from '@heroicons/react/24/outline';

const KeyFeaturesSection = () => {
    const sectionRef = useRef(null);

    // Track the scroll position of the section
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ["start end", "end start"], // Track when the section enters and leaves the viewport
    });

    // Adjusted scroll progress thresholds to reveal cards lower in the viewport
    const y1 = useTransform(scrollYProgress, [0.15, 0.30], [50, 0]);  // Cards will start to appear later (0.4 - 0.55)
    const opacity1 = useTransform(scrollYProgress, [0.15, 0.30], [0, 1]);

    const y2 = useTransform(scrollYProgress, [0.25, 0.40], [50, 0]);
    const opacity2 = useTransform(scrollYProgress, [0.25, 0.40], [0, 1]);

    const y3 = useTransform(scrollYProgress, [0.35, 0.50], [50, 0]);
    const opacity3 = useTransform(scrollYProgress, [0.35, 0.50], [0, 1]);

    return (
        <section ref={sectionRef} className="py-24 bg-backgroundLightGray">
            <div className="max-w-7xl mx-auto px-4 text-center mx-10">
                <h2 className="font-heading text-5xl font-extrabold text-primaryDarkBlue mb-12">Key Features</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
                    {/* Feature 1 */}
                    <motion.div
                        style={{ y: y1, opacity: opacity1 }}
                        className="transition-transform duration-500"
                    >
                        <BoltIcon className="h-12 w-12 text-accentOrange mx-auto mb-4" />
                        <h3 className="text-2xl font-semibold text-primaryBlue mb-4">Automation at Scale</h3>
                        <p className="text-gray-600">
                            Automate your scheduling process, saving time and reducing manual effort while
                            optimizing your daily operations.
                        </p>
                    </motion.div>

                    {/* Feature 2 */}
                    <motion.div
                        style={{ y: y2, opacity: opacity2 }}
                        className="transition-transform duration-500"
                    >
                        <ChartBarIcon className="h-12 w-12 text-accentOrange mx-auto mb-4" />
                        <h3 className="text-2xl font-semibold text-primaryBlue mb-4">Analytics and Insights</h3>
                        <p className="text-gray-600">
                            Gain valuable insights into your business with powerful analytics that help you track
                            growth and performance.
                        </p>
                    </motion.div>

                    {/* Feature 3 */}
                    <motion.div
                        style={{ y: y3, opacity: opacity3 }}
                        className="transition-transform duration-500"
                    >
                        <ClockIcon className="h-12 w-12 text-accentOrange mx-auto mb-4" />
                        <h3 className="text-2xl font-semibold text-primaryBlue mb-4">Real-time Scheduling</h3>
                        <p className="text-gray-600">
                            Adjust your schedule dynamically based on real-time factors like weather or team
                            availability.
                        </p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default KeyFeaturesSection;
