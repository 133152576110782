import React from 'react';
import { Link } from 'react-router-dom';
import {  } from '@heroicons/react/solid';
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

const ErrorPage = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-backgroundLight">
            {/* Icon */}
            <div className="mb-6">
                <ExclamationCircleIcon className="h-20 w-20 text-accentOrange" />
            </div>

            {/* Error Message */}
            <h1 className="text-6xl font-heading font-extrabold text-primaryBlue mb-4">404</h1>
            <p className="text-xl text-gray-500 mb-6 text-center">
                Oops! The page you're looking for doesn't exist.
            </p>
            <p className="text-base text-gray-500 mb-8 text-center">
                It looks like the page you were trying to reach doesn't exist anymore or has been moved.
            </p>

            {/* Back to Home Button */}
            <Link
                to="/"
                className="bg-primaryBlue text-white px-6 py-3 rounded-lg font-semibold shadow-md hover:bg-accentOrange transition-all duration-300"
            >
                Go Back to Home
            </Link>
        </div>
    );
};

export default ErrorPage;
