import React from 'react';

const SuccessMessage = ({ successMessage, email }) => {
    return (
        <div className="text-center">
            <h2 className="text-3xl font-heading text-green-500 mb-6">Success!</h2>
            <p className="text-lg text-gray-600 mb-4">{successMessage}</p>
            <p className="text-gray-500">
                We’ve sent a verification link to{' '}
                <span className="font-semibold text-primaryBlue">{email}</span>.
            </p>
        </div>
    );
};

export default SuccessMessage;
