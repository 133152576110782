import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendContactForm } from '../../api/contactApi';  // Import the API function

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            // Send form data to the backend using the sendContactForm function
            await sendContactForm(formData);
            setSubmitted(true);
            setTimeout(() => navigate('/'), 3000);  // Redirect after 3 seconds
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-4xl mx-auto bg-white p-10 rounded-lg shadow-lg">
                <h1 className="font-heading text-5xl text-primaryBlue font-extrabold mb-8 text-center">Contact Us</h1>

                <p className="text-lg text-gray-600 text-center mb-8">
                    Have questions or feedback? We’d love to hear from you!
                </p>

                {submitted ? (
                    <div className="text-center">
                        <p className="text-green-600 font-semibold">
                            Thanks for contacting us! We'll get back to you soon.
                        </p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="grid gap-6">
                        <div>
                            <label className="block font-semibold text-gray-700 mb-2" htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                required
                            />
                        </div>

                        <div>
                            <label className="block font-semibold text-gray-700 mb-2" htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                required
                            />
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </div>

                        <div>
                            <label className="block font-semibold text-gray-700 mb-2" htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                placeholder="Write your message here..."
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                rows="6"
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="bg-accentOrange text-white py-3 px-6 rounded-lg font-semibold hover:bg-primaryBlue transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send Message'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ContactPage;
