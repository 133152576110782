import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { validateCountry, registerUser } from '../../api/userApi';  // Import the validateCountry function
import PhoneNumberInput from './PhoneNumberInput';
import PasswordValidation from './PasswordValidation';

const RegistrationForm = ({ initialFormData, setSuccessMessage, setLoading, loading, setCountryMismatchData, setShowCountryConfirmation, formData, setFormData }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        symbol: false,
        uppercase: false,
        lowercase: false,
        number: false,
    });
    const [error, setError] = useState(null); // Error state to display any errors during form submission

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);  // Clear previous errors
        setLoading(true);  // Set loading to true

        try {
            // Password validation before API call
            if (!passwordValidations.length || !passwordValidations.symbol || !passwordValidations.uppercase || !passwordValidations.lowercase || !passwordValidations.number) {
                setLoading(false);
                return setError('Password must be at least 8 characters long and include a symbol, an uppercase letter, a lowercase letter, and a number.');
            }

            // Step 1: Validate country before proceeding with registration
            try {
                const validationResponse = await validateCountry(formData);
                console.log('Country validation successful:', validationResponse);

                // If validation succeeds, proceed with registration
                await registerUser(formData);
                setSuccessMessage('Registration successful! Please check your email to verify your account.');
            } catch (validationError) {
                if (validationError.error && validationError.error.includes('Country mismatch')) {
                    console.log('Country mismatch detected, triggering modal...');
                    setCountryMismatchData(validationError);  // Set the mismatch data for modal
                    setShowCountryConfirmation(true);  // Show the country confirmation modal
                } else {
                    throw validationError;  // Handle any other validation error
                }
            }

        } catch (err) {
            console.error('Error during registration:', err);
            setError(err.message || 'Failed to register');
        } finally {
            setLoading(false);  // Turn off loading at the end
        }
    };

    // Handle input changes (for both email, password, and phone fields)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        // Password validation to match the backend rules
        if (name === 'password') {
            setPasswordValidations({
                length: value.length >= 8,  // Minimum 8 characters
                symbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),  // At least one symbol
                uppercase: /[A-Z]/.test(value),  // At least one uppercase letter
                lowercase: /[a-z]/.test(value),  // At least one lowercase letter
                number: /\d/.test(value),  // At least one number
            });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 w-full">
            {/* Email input */}
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                required
            />

            {/* Password input with visibility toggle */}
            <div className="relative">
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                    required
                />
                <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                >
                    {passwordVisible ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                </button>
            </div>

            {/* Password Validation */}
            <PasswordValidation passwordValidations={passwordValidations} />

            {/* Phone number input */}
            <PhoneNumberInput formData={formData} setFormData={setFormData} />

            {/* Error display */}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            {/* Submit button */}
            <button
                type="submit"
                className="w-full bg-primaryBlue text-white py-2 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                disabled={loading}
            >
                {loading ? 'Registering...' : 'Register'}
            </button>
        </form>
    );
};

export default RegistrationForm;
