// Loading.jsx
import React from 'react';
import ClockLoader from './ClockLoader';

const Loading = ({ text = 'Loading...' }) => (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-80 z-50">
        <ClockLoader />
        <p
            className="mt-4 text-primaryBlue text-lg font-semibold"
            role="status"
            aria-live="polite"
        >
            {text}
        </p>
    </div>
);

export default Loading;
