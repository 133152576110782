import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const accessToken = localStorage.getItem('accessToken');  // Using the access token

    if (!accessToken) {
        // Redirect to login if no access token is found
        return <Navigate to="/login" />;
    }

    return children;  // Allow access if access token is valid
};

export default ProtectedRoute;
