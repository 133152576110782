import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { ArrowRightIcon, MapIcon, ExclamationCircleIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

const UniqueSellingPointsSection = () => {
    const sectionRef1 = useRef(null);
    const sectionRef2 = useRef(null);
    const sectionRef3 = useRef(null);
    const sectionRef4 = useRef(null);

    // Track the scroll position for each item individually
    const { scrollYProgress: scrollY1 } = useScroll({ target: sectionRef1, offset: ["start end", "end center"] });
    const { scrollYProgress: scrollY2 } = useScroll({ target: sectionRef2, offset: ["start end", "end center"] });
    const { scrollYProgress: scrollY3 } = useScroll({ target: sectionRef3, offset: ["start end", "end center"] });
    const { scrollYProgress: scrollY4 } = useScroll({ target: sectionRef4, offset: ["start end", "end center"] });

    // Define transforms for each item (parallax and fade-in)
    const start = 0.2;
    const end = 0.55;

    const yIcon1 = useTransform(scrollY1, [start, end], [30, 0]); // Parallax effect for icons
    const yText1 = useTransform(scrollY1, [start, end], [20, 0]); // Parallax effect for text
    const opacity1 = useTransform(scrollY1, [start, end], [0, 1]); // Fade-in effect

    const yIcon2 = useTransform(scrollY2, [start, end], [30, 0]);
    const yText2 = useTransform(scrollY2, [start, end], [20, 0]);
    const opacity2 = useTransform(scrollY2, [start, end], [0, 1]);

    const yIcon3 = useTransform(scrollY3, [start, end], [30, 0]);
    const yText3 = useTransform(scrollY3, [start, end], [20, 0]);
    const opacity3 = useTransform(scrollY3, [start, end], [0, 1]);

    const yIcon4 = useTransform(scrollY4, [start, end], [30, 0]);
    const yText4 = useTransform(scrollY4, [start, end], [20, 0]);
    const opacity4 = useTransform(scrollY4, [start, end], [0, 1]);

    return (
        <section className="py-24 bg-white">
            <div className="max-w-7xl mx-auto px-4 mx-10">
                <motion.h2
                    className="font-heading text-5xl font-extrabold text-primaryDarkBlue text-center mb-12"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    Why ScheduCo is the Best Choice
                </motion.h2>

                <div className="space-y-16">
                    {/* Line 1 - Icon Left, Text Right */}
                    <motion.div
                        ref={sectionRef1}
                        style={{ opacity: opacity1, y: yText1 }}
                        className="flex flex-col md:flex-row items-center h-full rounded-xl p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300"
                    >
                        <motion.div
                            style={{ y: yIcon1 }}
                            className="md:w-1/3 flex items-center justify-center h-full"
                        >
                            <ArrowRightIcon className="h-16 w-16 text-accentOrange" />
                        </motion.div>
                        <motion.div
                            style={{ y: yText1 }}
                            className="md:w-2/3 text-center md:text-left"
                        >
                            <h3 className="text-2xl font-bold text-primaryBlue mb-4">Built for Recurring Jobs</h3>
                            <p className="text-gray-600">
                                ScheduCo is designed specifically for businesses with recurring jobs like landscaping or cleaning services.
                            </p>
                        </motion.div>
                    </motion.div>

                    {/* Line 2 - Icon Right, Text Left */}
                    <motion.div
                        ref={sectionRef2}
                        style={{ opacity: opacity2, y: yText2 }}
                        className="flex flex-col md:flex-row items-center md:flex-row-reverse h-full rounded-xl p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300"
                    >
                        <motion.div
                            style={{ y: yIcon2 }}
                            className="md:w-1/3 flex items-center justify-center h-full"
                        >
                            <MapIcon className="h-16 w-16 text-accentOrange" />
                        </motion.div>
                        <motion.div
                            style={{ y: yText2 }}
                            className="md:w-2/3 text-center md:text-left"
                        >
                            <h3 className="text-2xl font-bold text-primaryBlue mb-4">Route Optimization</h3>
                            <p className="text-gray-600">
                                ScheduCo's advanced route optimization ensures the most efficient routes, reducing travel time and fuel costs.
                            </p>
                        </motion.div>
                    </motion.div>

                    {/* Line 3 - Icon Left, Text Right */}
                    <motion.div
                        ref={sectionRef3}
                        style={{ opacity: opacity3, y: yText3 }}
                        className="flex flex-col md:flex-row items-center h-full rounded-xl p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300"
                    >
                        <motion.div
                            style={{ y: yIcon3 }}
                            className="md:w-1/3 flex items-center justify-center h-full"
                        >
                            <ExclamationCircleIcon className="h-16 w-16 text-accentOrange" />
                        </motion.div>
                        <motion.div
                            style={{ y: yText3 }}
                            className="md:w-2/3 text-center md:text-left"
                        >
                            <h3 className="text-2xl font-bold text-primaryBlue mb-4">Job Prioritization</h3>
                            <p className="text-gray-600">
                                ScheduCo automatically prioritizes missed or delayed jobs for the next available slot.
                            </p>
                        </motion.div>
                    </motion.div>

                    {/* Line 4 - Icon Right, Text Left */}
                    <motion.div
                        ref={sectionRef4}
                        style={{ opacity: opacity4, y: yText4 }}
                        className="flex flex-col md:flex-row items-center md:flex-row-reverse h-full rounded-xl p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300"
                    >
                        <motion.div
                            style={{ y: yIcon4 }}
                            className="md:w-1/3 flex items-center justify-center h-full"
                        >
                            <WrenchScrewdriverIcon className="h-16 w-16 text-accentOrange" />
                        </motion.div>
                        <motion.div
                            style={{ y: yText4 }}
                            className="md:w-2/3 text-center md:text-left"
                        >
                            <h3 className="text-2xl font-bold text-primaryBlue mb-4">Built-in Equipment Management</h3>
                            <p className="text-gray-600">
                                ScheduCo helps you manage your schedule and equipment, tracking usage, maintenance schedules, and performance.
                            </p>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default UniqueSellingPointsSection;
