import React, { useEffect, useState } from 'react';
import { enableMfa, confirmMfaToken } from '../../api/userApi';
import { useNavigate } from 'react-router-dom';

const MfaSetupPage = () => {
    const [qrCode, setQrCode] = useState('');
    const [mfaToken, setMfaToken] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMfaSetup = async () => {
            setLoading(true);
            try {
                const response = await enableMfa();  // Fetch QR code and MFA secret from backend
                setQrCode(response.qrCode);  // Store the QR code URL
            } catch (err) {
                setError('Failed to generate MFA setup. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchMfaSetup();
    }, []);

    const handleConfirmMfa = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await confirmMfaToken(mfaToken);
            setSuccessMessage('MFA setup successfully completed!');
            setTimeout(() => navigate('/dashboard'), 3000);  // Redirect to dashboard after success
        } catch (err) {
            setError('Invalid MFA token. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg text-center">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6">Set Up MFA</h1>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
                {loading ? (
                    <p className="text-primaryBlue">Loading...</p>
                ) : qrCode ? (
                    <>
                        <p className="text-gray-600 mb-4">Scan the QR code below with your authentication app:</p>
                        <img src={qrCode} alt="MFA QR Code" className="mx-auto mb-6" />
                        <form onSubmit={handleConfirmMfa} className="grid gap-4">
                            <input
                                type="text"
                                placeholder="Enter MFA code"
                                value={mfaToken}
                                onChange={(e) => setMfaToken(e.target.value)}
                                className="p-3 border border-gray-300 rounded-lg focus:outline-none"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-primaryBlue text-white py-2 px-6 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                                disabled={loading}
                            >
                                {loading ? 'Confirming...' : 'Confirm MFA'}
                            </button>
                        </form>
                    </>
                ) : (
                    <p className="text-gray-600">Generating MFA setup...</p>
                )}
            </div>
        </div>
    );
};

export default MfaSetupPage;
