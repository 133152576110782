import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmMfaToken } from '../../api/userApi';  // Assuming this is the API function for confirming MFA

const ConfirmMfaPage = () => {
    const [mfaCode, setMfaCode] = useState(Array(6).fill(''));  // Create an array for the 6 digits
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Set focus to the first input when the page loads
    useEffect(() => {
        document.getElementById('mfa-input-0').focus();
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {  // Ensure only digits are entered
            const newMfaCode = [...mfaCode];
            newMfaCode[index] = value;
            setMfaCode(newMfaCode);

            // Automatically focus on the next box if a digit is entered
            if (value && index < 5) {
                document.getElementById(`mfa-input-${index + 1}`).focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const code = mfaCode.join('');  // Join the array into a single string

        try {
            // Send the MFA code and get the refreshed access token and refresh token
            const response = await confirmMfaToken(code);  // Assumed response includes accessToken and refreshToken

            // Store the new tokens in localStorage
            localStorage.setItem('accessToken', response.accessToken);
            localStorage.setItem('refreshToken', response.refreshToken);

            // Redirect to dashboard after storing the tokens
            navigate('/dashboard');
        } catch (err) {
            setError('Failed to confirm MFA. Please check the code and try again.');
            setMfaCode(Array(6).fill(''));  // Reset the MFA code input fields
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);  // Trigger form submission on Enter key
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-8">
            <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg text-center">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6">Confirm MFA</h1>
                <p className="text-gray-600 mb-4">
                    Enter the 6-digit code from your authenticator app to complete the MFA setup.
                </p>
                {error && <p className="text-red-500 mb-4">{error}</p>}

                {/* MFA Input with two blocks of three inputs and a dash in between */}
                <form onSubmit={handleSubmit} className="flex justify-center items-center gap-4 mb-6">
                    <div className="flex gap-2">
                        {mfaCode.slice(0, 3).map((digit, index) => (
                            <input
                                key={index}
                                id={`mfa-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={handleKeyDown}  // Handle Enter key press
                                className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                required
                            />
                        ))}
                    </div>
                    <span className="text-2xl text-gray-500">-</span> {/* Dash in between */}
                    <div className="flex gap-2">
                        {mfaCode.slice(3).map((digit, index) => (
                            <input
                                key={index + 3}  // Adjust index for the second group
                                id={`mfa-input-${index + 3}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(e, index + 3)}
                                onKeyDown={handleKeyDown}  // Handle Enter key press
                                className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                                required
                            />
                        ))}
                    </div>
                </form>

                <button
                    onClick={handleSubmit}
                    className="bg-primaryBlue text-white py-2 px-6 rounded-lg font-semibold hover:bg-accentOrange transition duration-300"
                    disabled={loading}
                >
                    {loading ? 'Confirming...' : 'Confirm MFA'}
                </button>
            </div>
        </div>
    );
};

export default ConfirmMfaPage;
