import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ blog }) => {
    return (
        <article className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
            {blog.imageUrl && (
                <img
                    src={blog.imageUrl}
                    alt={blog.title}
                    className="h-48 w-full object-cover"
                />
            )}
            <div className="p-6">
                <h2 className="font-heading text-2xl font-bold text-primaryBlue mb-4">
                    {blog.title}
                </h2>
                <p className="text-base text-gray-600 mb-6 line-clamp-3">
                    {blog.metaDescription}
                </p>
                <Link
                    to={`/blog/${blog.slug}`}
                    className="text-accentOrange font-semibold hover:underline"
                >
                    Read More →
                </Link>
            </div>
        </article>
    );
};

export default BlogCard;
