import React from 'react';

const PasswordValidation = ({ passwordValidations }) => {
    return (
        <ul className="text-sm mt-2">
            {/* Length Validation */}
            <li
                className={`flex items-center ${
                    passwordValidations.length ? 'text-green-500' : 'text-gray-500'
                }`}
            >
                <span className="mr-2">{passwordValidations.length ? '✔' : '✖'}</span>
                At least 8 characters
            </li>

            {/* Symbol Validation */}
            <li
                className={`flex items-center ${
                    passwordValidations.symbol ? 'text-green-500' : 'text-gray-500'
                }`}
            >
                <span className="mr-2">{passwordValidations.symbol ? '✔' : '✖'}</span>
                At least one symbol
            </li>

            {/* Uppercase Validation */}
            <li
                className={`flex items-center ${
                    passwordValidations.uppercase ? 'text-green-500' : 'text-gray-500'
                }`}
            >
                <span className="mr-2">{passwordValidations.uppercase ? '✔' : '✖'}</span>
                At least one uppercase letter
            </li>

            {/* Lowercase Validation */}
            <li
                className={`flex items-center ${
                    passwordValidations.lowercase ? 'text-green-500' : 'text-gray-500'
                }`}
            >
                <span className="mr-2">{passwordValidations.lowercase ? '✔' : '✖'}</span>
                At least one lowercase letter
            </li>

            {/* Number Validation */}
            <li
                className={`flex items-center ${
                    passwordValidations.number ? 'text-green-500' : 'text-gray-500'
                }`}
            >
                <span className="mr-2">{passwordValidations.number ? '✔' : '✖'}</span>
                At least one number
            </li>
        </ul>
    );
};

export default PasswordValidation;
