import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const Hotjar = () => {
    const location = useLocation();

    useEffect(() => {
        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: 5144832, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }, []);

    // Trigger Hotjar virtual page view on route change
    useEffect(() => {
        if (window.hj) {
            window.hj('stateChange', location.pathname);  // Track the current route
        }
    }, [location]);  // Track every route change


    return null; // No need to render anything
};

export default Hotjar;
