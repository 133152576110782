import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RegistrationForm from '../../components/registration/RegistrationForm';
import SuccessMessage from '../../components/registration/SuccessMessage';
import Loading from '../../components/Loading';
import { validateCountry, registerUser } from '../../api/userApi';
import CountryConfirmationModal from '../../components/registration/CountryConfirmationModal';

const RegistrationPage = () => {
    const location = useLocation();
    const confirmedCountry = location.state?.businessCountry || '';

    // Initialize formData with default values if location.state is undefined
    const initialFormData = location.state?.formData || {
        email: '',
        password: '',
        phone: '',
        country: confirmedCountry,
        role: 'user',
    };

    const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || '');
    const [loading, setLoading] = useState(false);
    const [countryMismatchData, setCountryMismatchData] = useState(null);
    const [showCountryConfirmation, setShowCountryConfirmation] = useState(false);
    const [formData, setFormData] = useState(initialFormData);  // Initialize formData here
    const [error, setError] = useState(null); // Declare error state

    // Handle country change in the modal
    const handleCountryChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            country: e.target.value,
        }));
    };

    // Handle validation before registration
    const handleValidateCountry = async () => {
        setLoading(true);
        setError(null);  // Clear previous errors before the request

        try {
            // Call the API to validate country mismatch
            const response = await validateCountry(formData);
            console.log("Country validation successful:", response);

            // If country validation passes, proceed with registration
            await handleConfirmCountry();
        } catch (err) {
            if (err.error && err.error.includes('Country mismatch')) {
                console.log("Country mismatch detected, showing modal...");
                setCountryMismatchData(err);  // Set the mismatch data for modal
                setShowCountryConfirmation(true);  // Show the country confirmation modal
            } else {
                console.log("Error during country validation:", err.message || err);
                setError(err.message || 'Country validation failed.');
            }
        } finally {
            setLoading(false);  // Always turn off loading at the end
        }
    };

    // Handle confirm country in the modal
    const handleConfirmCountry = async () => {
        setLoading(true);
        setError(null);  // Clear previous errors before the request

        try {
            // Log formData for debugging purposes
            console.log("Submitting form with corrected country:", formData);

            // Call the API to register the user with the updated country
            await registerUser(formData);
            setSuccessMessage('Registration successful! Please check your email to verify your account.');
            setShowCountryConfirmation(false);  // Hide modal upon success
        } catch (err) {
            console.log("Error during registration:", err.response?.data || err.message);
            setError(err.response?.data?.error || err.message || 'Failed to confirm country and register');
        } finally {
            setLoading(false);  // Always turn off loading at the end
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-backgroundLight p-4">
            {loading && <Loading />}
            <div className="max-w-md w-full bg-white px-4 py-6 rounded-lg shadow-lg relative">

                {/* Display error message if there's any */}
                {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

                {successMessage ? (
                    <SuccessMessage successMessage={successMessage} email={formData.email} />
                ) : (
                    <RegistrationForm
                        initialFormData={initialFormData}
                        setSuccessMessage={setSuccessMessage}
                        setLoading={setLoading}
                        loading={loading}
                        setCountryMismatchData={setCountryMismatchData}
                        setShowCountryConfirmation={setShowCountryConfirmation}
                        formData={formData}  // Pass formData and setFormData to the form
                        setFormData={setFormData}
                        handleValidateCountry={handleValidateCountry}  // Call validate before registration
                    />
                )}

                {/* Show Country Confirmation Modal if there is a country mismatch */}
                {showCountryConfirmation && (
                    <CountryConfirmationModal
                        countryMismatchData={countryMismatchData}
                        formData={formData}
                        handleCountryChange={handleCountryChange}
                        handleConfirmCountry={handleConfirmCountry}
                        loading={loading}  // Disable the confirm button during loading
                    />
                )}
            </div>
        </div>
    );
};

export default RegistrationPage;
