// PhoneNumberInput.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { allCountries } from 'country-telephone-data';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumberType } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

// Update countryOptions to use ISO code as the value
const countryOptions = allCountries.map((country) => ({
    value: country.iso2.toUpperCase(), // Use ISO code as value
    label: country.name.replace(/\s*\(.*?\)\s*/g, ''),
    code: country.iso2.toUpperCase(),
    dialCode: `+${country.dialCode}`,
}));

const PhoneNumberInput = ({ formData, setFormData, className }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [phoneError, setPhoneError] = useState('');
    const [phonePlaceholder, setPhonePlaceholder] = useState('Phone Number');

    // Update window width on resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch IP Geolocation on load to prefill country code
    useEffect(() => {
        const fetchGeolocation = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                if (response.data && response.data.country_code) {
                    const country = response.data.country_code.toUpperCase();
                    setFormData((prevData) => ({
                        ...prevData,
                        country: country,
                    }));
                }
            } catch (error) {
                console.error('Failed to fetch geolocation:', error);
            }
        };
        fetchGeolocation();
    }, [setFormData]);

    // Find the selected country based on formData.country
    const selectedCountry = countryOptions.find(
        (option) => option.value === formData.country
    );

    // Update phone placeholder when selected country changes
    useEffect(() => {
        if (selectedCountry) {
            try {
                const exampleNumber = phoneUtil.getExampleNumberForType(
                    selectedCountry.code,
                    PhoneNumberType.MOBILE
                );
                const formattedNumber = phoneUtil.format(
                    exampleNumber,
                    PhoneNumberFormat.NATIONAL
                );
                setPhonePlaceholder(formattedNumber);
            } catch (error) {
                setPhonePlaceholder('Phone Number');
            }
        } else {
            setPhonePlaceholder('Phone Number');
        }
    }, [selectedCountry]);

    const handleCountryCodeChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            country: selectedOption.value, // Use 'country' instead of 'countryCode'
        }));
    };

    const handlePhoneChange = (e) => {
        const inputNumber = e.target.value;
        const country = formData.country || 'US'; // Default to 'US' if not set

        try {
            // Remove any formatting or extra characters
            const rawInput = inputNumber.replace(/[^+\d]/g, '');
            let number;

            // Parse the number using the country code
            if (rawInput.startsWith('+')) {
                number = phoneUtil.parse(rawInput);
            } else {
                number = phoneUtil.parse(rawInput, country);
            }

            const isValid = phoneUtil.isValidNumberForRegion(number, country);

            if (isValid) {
                // Format number to E.164 standard
                const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
                setFormData((prevData) => ({
                    ...prevData,
                    phone: formattedNumber,
                }));
                setPhoneError('');
            } else {
                // Handle invalid number
                setFormData((prevData) => ({
                    ...prevData,
                    phone: inputNumber,
                }));
                setPhoneError('Invalid phone number for the selected country.');
            }
        } catch (error) {
            // Handle parsing error
            setFormData((prevData) => ({
                ...prevData,
                phone: inputNumber,
            }));
            setPhoneError('Invalid phone number format.');
        }
    };

    // Define breakpoints
    const isMobile = windowWidth < 768; // Tailwind's 'md' breakpoint is 768px

    // Adjust styles based on screen size
    const customStyles = {
        control: (provided) => ({
            ...provided,
            position: 'relative',
            width: isMobile ? 80 : 120,
            minHeight: 'unset',
            height: isMobile ? '40px' : '48px',
            fontSize: isMobile ? '12px' : '14px',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.5rem',
            borderColor: '#D1D5DB', // Tailwind's border-gray-300
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#9CA3AF', // Tailwind's border-gray-400
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none', // Hide the dropdown indicator
        }),
        input: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            width: isMobile ? 250 : 300,
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '200px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: isMobile ? '12px' : '14px',
            backgroundColor: state.isSelected
                ? '#E5E7EB' // Tailwind's bg-gray-200
                : state.isFocused
                    ? '#F3F4F6' // Tailwind's bg-gray-100
                    : null,
            color: '#374151', // Tailwind's text-gray-700
            '&:active': {
                backgroundColor: '#D1D5DB', // Tailwind's bg-gray-300
            },
        }),
    };

    const formatOptionLabel = ({ dialCode, label, code }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReactCountryFlag
                countryCode={code}
                svg
                style={{
                    width: isMobile ? '1.2em' : '1.5em',
                    height: isMobile ? '1.2em' : '1.5em',
                    marginRight: '0.5em',
                }}
            />
            <span style={{ fontSize: isMobile ? '12px' : '14px' }}>
        {label} ({dialCode})
      </span>
        </div>
    );

    // Custom SingleValue component to display only the country code and flag
    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactCountryFlag
                    countryCode={props.data.code}
                    svg
                    style={{
                        width: isMobile ? '1em' : '1.2em',
                        height: isMobile ? '1em' : '1.2em',
                        marginRight: '0.25em',
                    }}
                />
                <span style={{ fontSize: isMobile ? '12px' : '14px' }}>
          {props.data.dialCode}
        </span>
            </div>
        </components.SingleValue>
    );

    return (
        <div className={`grid gap-2 ${className}`}>
            <div className="flex items-center gap-0">
                <Select
                    value={selectedCountry}
                    onChange={handleCountryCodeChange}
                    options={countryOptions}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                    components={{ SingleValue }}
                    isSearchable
                    aria-label="Country code selector"
                />
                <input
                    type="tel"
                    name="phone"
                    placeholder={phonePlaceholder}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    className={`flex-1 p-3 border ${
                        phoneError ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg focus:outline-none focus:ring-2 focus:ring-primaryBlue ${
                        isMobile ? 'h-10 text-sm' : 'h-12 text-base'
                    }`}
                    required
                />
            </div>
            {phoneError && <p className="text-red-500 text-sm mt-1">{phoneError}</p>}
        </div>
    );
};

export default PhoneNumberInput;
