// ClockLoader.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ClockLoader = () => {
    const [time, setTime] = useState(new Date());

    // Update time every second
    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return () => {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setTime(new Date());
    };

    // Calculate rotation angles
    const secondDegrees = (time.getSeconds() / 60) * 360;
    const minuteDegrees = (time.getMinutes() / 60) * 360 + (secondDegrees / 60);
    const hourDegrees =
        ((time.getHours() % 12) / 12) * 360 + (minuteDegrees / 12);

    return (
        <div className="relative h-32 w-32">
            {/* Clock Face */}
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="h-32 w-32 rounded-full bg-white border-4 border-primaryBlue shadow-lg"></div>
            </div>
            {/* Center Pivot */}
            <div className="absolute top-1/2 left-1/2 -mt-1 -ml-1 h-2 w-2 bg-primaryBlue rounded-full shadow"></div>
            {/* Hour Hand */}
            <motion.div
                className="absolute h-8 w-1 bg-primaryBlue origin-bottom"
                style={{
                    top: 'calc(50% - 32px)',
                    left: 'calc(50% - 2px)',
                }}
                animate={{ rotate: hourDegrees }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            ></motion.div>
            {/* Minute Hand */}
            <motion.div
                className="absolute h-12 w-1 bg-primaryBlue origin-bottom"
                style={{
                    top: 'calc(50% - 48px)',
                    left: 'calc(50% - 2px)',
                }}
                animate={{ rotate: minuteDegrees }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            ></motion.div>
            {/* Second Hand */}
            <motion.div
                className="absolute h-14 w-0.5 bg-red-500 origin-bottom"
                style={{
                    top: 'calc(50% - 56px)',
                    left: 'calc(50% - 1px)',
                }}
                animate={{ rotate: secondDegrees }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            ></motion.div>
        </div>
    );
};

export default ClockLoader;
