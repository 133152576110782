import React, { useState } from 'react';
import { createBlogPost } from '../../api/blogApi';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

const CreateBlogPost = () => {
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        metaTitle: '',
        metaDescription: '',
        keywords: '',
        status: 'draft',
        publishDate: '',  // New field to store the scheduled publish date
    });

    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setNotification(null);

        try {
            // Convert comma-separated keywords to an array
            const postData = { ...formData, keywords: formData.keywords.split(',').map(kw => kw.trim()) };
            await createBlogPost(postData);
            setNotification({ type: 'success', message: 'Blog post created successfully!' });
            setLoading(false);

            // Optionally redirect after a short delay
            setTimeout(() => navigate('/admin'), 2000);
        } catch (error) {
            console.error('Error creating post:', error);
            setNotification({ type: 'error', message: 'Error creating blog post.' });
            setLoading(false);
        }
    };

    // Handle changes in input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle TinyMCE content change
    const handleEditorChange = (content) => {
        setFormData((prevData) => ({ ...prevData, content }));
    };

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-4xl mx-auto bg-white p-10 rounded-lg shadow-lg">
                <h1 className="font-heading text-5xl text-primaryBlue font-extrabold mb-8 text-center">Create New Blog Post</h1>

                {/* Notification */}
                {notification && (
                    <div className={`mb-4 p-4 rounded text-center transition ${
                        notification.type === 'success'
                            ? 'bg-green-100 text-green-700'
                            : 'bg-red-100 text-red-700'
                    }`}>
                        {notification.message}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="grid gap-6">
                    {/* Title Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Title</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Enter the blog title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    {/* Content Field (TinyMCE Editor) */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Content</label>
                        <Editor
                            apiKey='jacno6c6i42xq1sgc2mu36zuvnre27qijvv4bj3smqa7zfxx'
                            init={{
                                plugins: [
                                    'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                    'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
                                ],
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                tinycomments_mode: 'embedded',
                                tinycomments_author: 'Author name',
                            }}
                            value={formData.content}
                            onEditorChange={handleEditorChange}
                        />
                    </div>

                    {/* Meta Title Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">SEO Meta Title</label>
                        <input
                            type="text"
                            name="metaTitle"
                            placeholder="SEO Meta Title"
                            value={formData.metaTitle}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    {/* Meta Description Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">SEO Meta Description</label>
                        <textarea
                            name="metaDescription"
                            placeholder="SEO Meta Description"
                            value={formData.metaDescription}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                            rows="4"
                            required
                        />
                    </div>

                    {/* Keywords Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Keywords (comma-separated)</label>
                        <input
                            type="text"
                            name="keywords"
                            placeholder="Enter keywords (comma separated)"
                            value={formData.keywords}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        />
                    </div>

                    {/* Publish Date Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Publish Date</label>
                        <input
                            type="date"
                            name="publishDate"
                            value={formData.publishDate}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                        />
                    </div>

                    {/* Status Field */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Status</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryBlue"
                            required
                        >
                            <option value="draft">Draft</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="published">Published</option>
                        </select>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`w-full py-3 px-6 rounded-lg font-semibold transition duration-300 ${
                            loading
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-accentOrange hover:bg-primaryBlue text-white'
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Post'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateBlogPost;
