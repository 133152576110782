import React, { useState, useEffect, useCallback } from 'react';
import { getCustomers, createCustomer, updateCustomer, deleteCustomer } from '../../api/customerApi';
import Modal from '../../components/ModalComponent';
import PhoneNumberInput from "../../components/registration/PhoneNumberInput";
import AddressForm from '../../components/customer/AddressForm';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import 'react-phone-input-2/lib/style.css';

const CustomerListPage = ({ userCountry }) => {
    const [customers, setCustomers] = useState([]);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [addCustomerLoading, setAddCustomerLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        totalPages: 0,
        totalCustomers: 0,
    });
    const [filters, setFilters] = useState({
        search: '',
        address: '',
        email: '',
    });
    const [sort, setSort] = useState({
        field: 'name',
        order: 'asc',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            postalCode: '',
            country: userCountry || '',
        },
        phone: '',
        countryCode: ''
    });

    // Stable fetchCustomers function using useCallback
    const fetchCustomers = useCallback(async () => {
        setCustomerLoading(true);
        setError(null);
        try {
            const data = await getCustomers(filters, pagination, sort);
            setCustomers(data.customers);
            setPagination((prev) => ({
                ...prev,
                totalPages: data.totalPages,
                totalCustomers: data.total,
            }));
        } catch (err) {
            setError(err.message || 'Failed to fetch customers');
        } finally {
            setCustomerLoading(false);
        }
        // eslint-disable-next-line
    }, [filters, pagination.page, pagination.limit, sort]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    const handlePageChange = (direction) => {
        setPagination((prev) => {
            const newPage = direction === 'next' ? prev.page + 1 : prev.page - 1;
            return { ...prev, page: Math.max(1, Math.min(newPage, prev.totalPages)) };
        });
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleSortChange = (field) => {
        setSort((prev) => ({
            field,
            order: prev.order === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleAddCustomer = async () => {
        setAddCustomerLoading(true);
        try {
            const response = await createCustomer(formData);
            if (response) {
                setIsModalOpen(false);
                setFormData({
                    name: '',
                    email: '',
                    address: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        country: userCountry || '',
                    },
                    phone: '',
                    countryCode: ''
                });
                fetchCustomers();
            }
        } catch (err) {
            setError('Failed to create customer');
        } finally {
            setAddCustomerLoading(false);
        }
    };

    const handleEditCustomer = (customer) => {
        setFormData({
            id: customer._id, // Ensure you have the ID for updating
            name: customer.name,
            email: customer.email,
            address: {
                street1: customer.address.street1,
                street2: customer.address.street2,
                city: customer.address.city,
                state: customer.address.state,
                postalCode: customer.address.postalCode,
                country: customer.address.country,
            },
            phone: customer.phone,
            countryCode: '', // Assuming you're handling country code elsewhere or it's part of the phone component logic
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const handleUpdateCustomer = async () => {
        if (!formData.id) {
            setError('No customer ID provided for update.');
            return; // Prevent update if no ID is provided
        }

        setAddCustomerLoading(true);
        try {
            const response = await updateCustomer(formData.id, formData); // Make sure to pass the ID and formData correctly
            if (response) {
                setIsModalOpen(false);
                setFormData({
                    name: '',
                    email: '',
                    address: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        country: userCountry || '',
                    },
                    phone: '',
                    countryCode: ''
                });
                await fetchCustomers(); // Refresh the list to show the updated data
            }
        } catch (err) {
            setError(`Failed to update customer: ${err.response ? err.response.data.error : err.message}`);
        } finally {
            setAddCustomerLoading(false);
        }
    };


    const handleDeleteCustomer = async (customerId) => {
        try {
            await deleteCustomer(customerId);
            await fetchCustomers();
        } catch (err) {
            setError('Failed to delete customer');
        }
    };

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-heading text-primaryBlue mb-6">Customer List</h1>

                {/* Search, Filters, and Sort */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 items-center">
                    <input
                        type="text"
                        name="search"
                        value={filters.search}
                        onChange={handleSearchChange}
                        placeholder="Search by name, email, or address"
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                    <input
                        type="text"
                        name="address"
                        value={filters.address}
                        onChange={handleSearchChange}
                        placeholder="Filter by address"
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                    <input
                        type="email"
                        name="email"
                        value={filters.email}
                        onChange={handleSearchChange}
                        placeholder="Filter by email"
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />

                    {/* Sort Button with Icon */}
                    <div className="w-full flex items-center">
                        <button
                            onClick={() => handleSortChange('name')}
                            className="w-full p-2 bg-primaryBlue text-white rounded-lg flex items-center justify-center hover:bg-primaryBlue-dark transition"
                        >
                            <svg
                                className="w-4 h-4 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h12M6 6h12M6 18h12" />
                            </svg>
                            Sort by {sort.field} ({sort.order === 'asc' ? 'A-Z' : 'Z-A'})
                        </button>
                    </div>
                </div>

                {/* Add Customer Button */}
                <div className="mb-6">
                    <button
                        onClick={() => {
                            setIsEditing(false);
                            setIsModalOpen(true);
                        }}
                        className="p-3 bg-primaryBlue text-white rounded-lg hover:bg-primaryBlue-dark transition"
                    >
                        Add New Customer
                    </button>
                </div>

                {/* Modal for Adding or Editing Customer */}
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <h2 className="text-2xl mb-4">{isEditing ? 'Edit Customer' : 'Add New Customer'}</h2>

                    {/* Customer Name Input */}
                    <input
                        type="text"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        placeholder="Customer Name"
                        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                    />

                    {/* Customer Email Input */}
                    <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        placeholder="Customer Email"
                        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primaryBlue focus:outline-none"
                    />

                    {/* Address Form */}
                    <AddressForm
                        formData={formData}
                        setFormData={setFormData}
                        userCountry={userCountry}
                    />

                    {/* Phone Number Input */}
                    <div className="my-4">
                        <PhoneNumberInput formData={formData} setFormData={setFormData} />
                    </div>

                    {/* Save or Update Customer Button */}
                    <button
                        onClick={isEditing ? handleUpdateCustomer : handleAddCustomer}
                        className="w-full p-3 bg-primaryBlue text-white rounded-lg hover:bg-primaryDarkBlue transition"
                        disabled={addCustomerLoading}
                    >
                        {addCustomerLoading ? 'Saving...' : isEditing ? 'Update Customer' : 'Save Customer'}
                    </button>
                </Modal>

                {/* Loading and Error States */}
                {customerLoading && <p className="text-primaryBlue text-center">Loading customers...</p>}
                {error && <p className="text-red-500 text-center">{error}</p>}

                {/* Customers Table */}
                {!customerLoading && !error && customers.length > 0 && (
                    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                        <table className="min-w-full text-left">
                            <thead>
                            <tr className="border-b">
                                <th className="p-4">Name</th>
                                <th className="p-4">Email</th>
                                <th className="p-4">Location</th>
                                <th className="p-4">Phone</th>
                                <th className="p-4">Created At</th>
                                <th className="p-4">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {customers.map((customer) => (
                                <tr key={customer._id} className="border-b hover:bg-gray-50">
                                    <td className="p-4">{customer.name}</td>
                                    <td className="p-4">{customer.email}</td>
                                    <td className="p-4">{customer.address ? `${customer.address.street1}, ${customer.address.city}, ${customer.address.state}, ${customer.address.country}` : 'N/A'}</td>
                                    <td className="p-4">{customer.phone || 'N/A'}</td>
                                    <td className="p-4">{new Date(customer.createdAt).toLocaleDateString()}</td>
                                    <td className="p-4 flex space-x-2">
                                        <button onClick={() => handleEditCustomer(customer)} className="text-blue-600">
                                            <PencilIcon className="w-5 h-5" />
                                        </button>
                                        <button onClick={() => handleDeleteCustomer(customer._id)} className="text-red-600">
                                            <TrashIcon className="w-5 h-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="flex justify-between items-center mt-6">
                            <button
                                onClick={() => handlePageChange('prev')}
                                disabled={pagination.page === 1}
                                className={`px-4 py-2 rounded-md font-semibold ${pagination.page === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-primaryBlue text-white hover:bg-primaryBlue-dark'}`}
                            >
                                Previous
                            </button>
                            <p className="text-gray-600">
                                Page {pagination.page} of {pagination.totalPages}
                            </p>
                            <button
                                onClick={() => handlePageChange('next')}
                                disabled={pagination.page === pagination.totalPages}
                                className={`px-4 py-2 rounded-md font-semibold ${pagination.page === pagination.totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-primaryBlue text-white hover:bg-primaryBlue-dark'}`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}

                {/* No Customers Found */}
                {!customerLoading && !error && customers.length === 0 && (
                    <div className="text-center text-gray-500">
                        <p>No customers found.</p>
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="mt-2 p-2 bg-primaryBlue text-white rounded"
                        >
                            Add New Customer
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomerListPage;