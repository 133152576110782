import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Correct import of jwtDecode


const DashboardPage = () => {
    const navigate = useNavigate();
    const [mfaEnabled, setMfaEnabled] = useState(true);  // Default to true, but will be updated after token decoding

    useEffect(() => {
        // Retrieve the access token from localStorage
        const token = localStorage.getItem('accessToken');

        if (token) {
            try {
                // Decode the JWT token to extract the payload
                const decodedToken = jwtDecode(token);
                // Extract the mfaEnabled status from the token payload
                const mfaStatus = decodedToken.mfaEnabled || false;
                setMfaEnabled(mfaStatus);  // Update the state based on the token data
            } catch (error) {
                console.error('Failed to decode token', error);
            }
        } else {
            // If no token, redirect to login
            navigate('/login');
        }
    }, [navigate]);

    const handleLogout = () => {
        // Clear any authentication tokens or data here
        localStorage.removeItem('token');  // Remove access token
        navigate('/login');  // Redirect to login page after logout
    };

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-7xl mx-auto text-center">
                <h1 className="text-4xl font-heading text-primaryBlue font-extrabold mb-6">
                    Welcome to Your Dashboard!
                </h1>

                {/* Display warning if MFA is not enabled */}
                {!mfaEnabled && (
                    <div className="bg-yellow-100 text-yellow-800 p-4 rounded-md mb-6">
                        <p>
                            For added security, we recommend enabling Multi-Factor Authentication (MFA).
                            <Link to="/ask-mfa" className="text-primaryBlue ml-2 font-semibold hover:underline">
                                Enable MFA now
                            </Link>
                        </p>
                    </div>
                )}

                <p className="text-lg text-gray-600 mb-6">
                    Here you can manage your account, view analytics, and more.
                </p>

                {/* Dummy Logout Button */}
                <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white py-2 px-6 rounded-lg font-semibold hover:bg-red-600 transition duration-300"
                >
                    Logout
                </button>
            </div>
        </div>
    );
};

export default DashboardPage;
