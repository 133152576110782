import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

// Components and Pages
import BlogPostPage from './pages/[slug]';
import AdminDashboard from './pages/admin/index';
import CreateBlogPost from './pages/admin/create';
import EditBlogPost from './components/blog/EditBlogPost';
import ViewBlogPost from './components/blog/ViewBlogPost';
import BlogPage from './pages/blog';
import AboutPage from './pages/about';
import SignupPage from './pages/signup';
import ContactPage from './pages/contact';
import LandingPage from './components/landingPage/LandingPage';
import RegistrationPage from './pages/user/RegistrationPage';
import EmailVerificationPage from './pages/user/EmailVerificationPage';
import LoginPage from './pages/user/LoginPage';
import ForgotPasswordPage from './pages/user/ForgotPasswordPage';
import ResetPasswordPage from "./pages/user/ResetPasswordPage";
import AskMfaPage from './pages/user/AskMfaPage';
import MfaSetupPage from './pages/user/MfaSetupPage';
import DashboardPage from './pages/user/DashboardPage';  // New dashboard page
import ErrorPage from './pages/ErrorPage';
import ProtectedRoute from './components/ProtectedRoute';
import ConfirmMfaPage from "./pages/user/ConfirmMfaPage";
import Hotjar from "./components/services/Hotjar";
import PlausibleTracker from './components/services/PlausibleTracker';
import ConditionalNavbar from "./components/navigation/ConditionalNavbar";
import ConfirmCountryPage from "./pages/user/ConfirmCountryPage";
import ParentComponent from "./components/customer/ParentComponent"; // Add PlausibleTracker


function App() {

    return (
        <Router>
            <PlausibleTracker/>
            <Hotjar/>
            <ConditionalNavbar/>
            <div className="pt-16">
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:slug" element={<BlogPostPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/contact" element={<ContactPage />} />

                    {/* Authentication Routes */}
                    <Route path="/register" element={<RegistrationPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="/verify-email" element={<EmailVerificationPage />} />
                    <Route path="/confirm-country" element={<ConfirmCountryPage/>}/>

                    {/* MFA-related Protected Routes */}
                    <Route path="/ask-mfa" element={<ProtectedRoute><AskMfaPage /></ProtectedRoute>} />
                    <Route path="/mfa-setup" element={<ProtectedRoute><MfaSetupPage /></ProtectedRoute>} />
                    <Route path="/confirm-mfa" element={<ProtectedRoute><ConfirmMfaPage /></ProtectedRoute>} />

                    {/* Dashboard */}
                    <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
                    <Route path="/customers" element={<ProtectedRoute><ParentComponent/></ProtectedRoute>} />

                    {/* Protected Admin Routes */}
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute>
                                <AdminDashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/create"
                        element={
                            <ProtectedRoute>
                                <CreateBlogPost />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/edit/:slug"
                        element={
                            <ProtectedRoute>
                                <EditBlogPost />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/view/:slug"
                        element={
                            <ProtectedRoute>
                                <ViewBlogPost />
                            </ProtectedRoute>
                        }
                    />

                    {/* Fallback Route */}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
