import React, { useEffect, useState } from 'react';
import { getAllPublishedBlogs } from '../../api/blogApi';
import { Link } from 'react-router-dom';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const result = await getAllPublishedBlogs();
                setBlogs(result.data);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-primaryBlue font-heading text-2xl animate-pulse">Loading blog posts...</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-backgroundLight p-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="font-heading text-5xl font-extrabold text-primaryBlue text-center mb-16">
                    Latest Blog Posts
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                    {blogs.map((blog) => (
                        <div key={blog.slug} className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300">
                            {blog.imageUrl && (
                                <img
                                    src={blog.imageUrl}
                                    alt={blog.title}
                                    className="h-48 w-full object-cover"
                                />
                            )}
                            <div className="p-6">
                                <h2 className="font-heading text-2xl font-bold text-primaryBlue mb-3">
                                    {blog.title}
                                </h2>
                                <p className="text-sm text-gray-500 mb-3">
                                    {new Date(blog.publishedAt).toLocaleDateString()} · {blog.readTime || '5 min read'}
                                </p>
                                <p className="text-base text-gray-700 mb-4 line-clamp-3">
                                    {blog.metaDescription}
                                </p>
                                <Link
                                    to={`/blog/${blog.slug}`}
                                    className="text-accentOrange font-semibold hover:underline"
                                >
                                    Read more →
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
