import React, { useEffect, useState } from 'react';
import BlogList from '../blog/BlogList';
import { getAllPublishedBlogs } from '../../api/blogApi';
import { Link } from 'react-router-dom';
import Hero from "./Hero";
import KeyFeaturesSection from "./KeyFeatures";
import UniqueSellingPointsSection from "./UniqueSellingPoints";

const HomePage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const result = await getAllPublishedBlogs();
                setBlogs(result.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchBlogs();
    }, []);

    return (
        <div className="min-h-screen bg-backgroundLightGray">
            {/* Hero Section */}
            <Hero/>


            {/* Key Features Section */}
            <KeyFeaturesSection/>

            {/* Unique Selling Points Section */}
            <UniqueSellingPointsSection/>

            {/* Testimonials Section */}
            <section className="py-24 bg-backgroundLightGray">
                <div className="max-w-6xl mx-auto text-center">
                    <h2 className="font-heading text-5xl font-extrabold text-primaryDarkBlue mb-12">Hear It from Industry Experts</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">

                        {/* Testimonial 1 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"Scheduco's platform has been a revelation for our operations. The preview alone has reshaped how we think about managing our teams and resources efficiently."</p>
                            <p className="font-semibold text-primaryBlue mt-4">– John D., CEO, Landscaping Services</p>
                        </div>

                        {/* Testimonial 2 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"The early prototypes of Scheduco promised—and delivered—significant improvements in our daily routing and scheduling. Can’t wait for the full version!"</p>
                            <p className="font-semibold text-primaryBlue mt-4">– Sarah K., Founder, Arboriculture</p>
                        </div>

                        {/* Testimonial 3 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"We've been involved with Scheduco from the early stages, and the insights into operational efficiency are exactly what we needed. Excited for what comes next!"</p>
                            <p className="font-semibold text-primaryBlue mt-4">– Mike W., Operations Lead, Commercial Gardening</p>
                        </div>

                        {/* Testimonial 4 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"Scheduco stands out as a solution that's designed with deep understanding of our challenges. It's more than a tool; it's a game-changer for our operational workflows."</p>
                            <p className="font-semibold text-primaryBlue mt-4">– Emily S., CEO, Residential Cleaning Services</p>
                        </div>

                        {/* Testimonial 5 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"Joining Scheduco’s early adopter program was a no-brainer. The vision they have for streamlining service operations aligns perfectly with our business goals."</p>
                            <p className="font-semibold text-primaryBlue mt-4">– Oliver M., Director, Home Maintenance Services</p>
                        </div>

                        {/* Testimonial 6 */}
                        <div className="p-8 bg-white rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                            <p className="text-gray-600 italic">"The dedication Scheduco’s team shows in understanding and addressing the specific needs of service-oriented businesses is impressive. Eager to see the impact of their full capabilities!"</p>
                            <p className="font-semibold text-primaryBlue mt-4">– Rachel L., Operations Manager, Window Cleaning</p>
                        </div>

                    </div>
                </div>
            </section>

            {/* Blog Section */}
            <section className="py-24 bg-gray-50">
                <div className="max-w-6xl mx-auto text-center">
                    <h2 className="font-heading text-4xl font-extrabold text-primaryDarkBlue mb-12">Latest Articles</h2>
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <p className="text-accentOrange font-heading text-2xl animate-pulse">Loading...</p>
                        </div>
                    ) : (
                        <BlogList blogs={blogs}/>
                    )}
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="py-24 bg-primaryDarkBlue text-white text-center">
                <h2 className="font-heading text-4xl font-extrabold mb-6">Ready to Take Your Business to the Next
                    Level?</h2>
                <p className="text-xl max-w-xl mx-auto mb-8">
                    Experience the ease of automation with Scheduco. Sign up for a free trial today and see how we can
                    help your business scale effortlessly.
                </p>
                <Link
                    to="/signup"
                    className="bg-accentOrange text-backgroundLightGray px-6 py-3 rounded-lg font-semibold shadow-lg hover:bg-primaryBlue hover:text-backgroundLightGray transition-all duration-300"
                >
                    Get Early Access
                </Link>
            </section>

            {/* Footer */}
            <footer className="py-12 bg-backgroundDark text-center">
                <p className="text-gray-400">© {new Date().getFullYear()} Scheduco. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default HomePage;
